import React from "react";

const Footer = () => {
    const OpenCashfree = () => {
        // window.open("https://payments.cashfree.com/forms/vajra-learning-workshop", "_blank");
    }
  return (
    <div className="w-full text-zinc-200 font-semibold h-96 bg-zinc-900 flex items-center justify-center text-center text-4xl">
      <div className=" w-3/4 h-60 grid text-left grid-cols-3 gap-6 font-Poetson   ">
        <div className=" rounded-2xl p-2 ring-[0.5px] ring-zinc-600 capitalize  bg-zinc-950 shadow-lg shadow-zinc-800">
          about
        </div>
        <div className=" rounded-2xl p-2 ring-[0.5px] ring-zinc-600 capitalize bg-zinc-950 shadow-lg shadow-zinc-800">
          brand
        </div>
        <div onClick={OpenCashfree} className=" rounded-2xl p-2 ring-[0.5px] ring-zinc-600 capitalize bg-zinc-950 shadow-lg shadow-zinc-800">
          contact
        </div>
      </div>
     
    </div>
  );
};
 
export default Footer;
