import React from "react";
import { motion } from "framer-motion";
const DetailSection = () => {
  return (
    <div className="my-8">
      <div className="lg:grid  mx-auto lg:p-6 p-3 grid-cols-2">
        <motion.div
          initial={{ opacity: 0, translateX: -200 }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            transition: { delay: 0.6, duration: 2, type: "spring" },
          }}
        >
          <img
            className="rounded-2xl"
            src="https://images.pexels.com/photos/4974920/pexels-photo-4974920.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, translateX: 200 }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            transition: { delay: 0.6, duration: 2, type: "spring" },
          }}
          className="text-zinc-300 mt-6 lg:mt-0 lg:flex items-center justify-center font-bold"
        >
          <div className="lg:w-4/5 m-4  lg:text-5xl text-2xl  text-left ">
            <h1 >Embark on Your Coding Journey with Vajra Learning Hub</h1>
            <p className="text-sm font-normal mt-2">
              Welcome to Vajra Learning Hub, where we believe that anyone can
              learn coding, regardless of their background or experience. Our
              mission is to provide a supportive and inclusive environment for
              complete beginners to explore the exciting world of programming.
              With our comprehensive program, you will gain hands-on experience
              in various languages and technologies, including C programming,
              JavaScript, React, MERN stack, blockchain development, Python, and
              DevOps. Start your coding journey today and unlock endless
              possibilities!
            </p>
          </div>
          <div></div>
        </motion.div>
      </div>
    </div>
  );
};

export default DetailSection;
