import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Register from './Pages/Register';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App bg-zinc-950 w-full overflow-hidden">
      
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/register' element={<Register/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
