import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
const HeroSection = () => {
    const navigate = useNavigate()
  return (
    <div className="lg:grid  mx-auto lg:p-6 p-3 lg:grid-cols-2">
      <motion.div
        initial={{ opacity: 0, translateX: -200 }}
        whileInView={{
          opacity: 1,
          translateX: 0,
          transition: { duration: 1, type: "spring" },
        }}
        className="text-zinc-300 flex items-center justify-center font-bold"
      >
        <div className="lg:w-4/5 m-4 lg:text-5xl text-2xl  text-left ">
          <h1 >Unlock Your Coding Potential with Vajra Learning Hub</h1>
          <p className="text-sm font-normal mt-2">
            Embark on a coding journey and master the art of programming with
            our comprehensive workshops.
          </p>
          <div className="mt-2">
            <div className=" w-fit h-14 flex items-center relative">
              <button className=" absolute w-40 border p-2 top-0  h-12 text-lg px-6 border-yellow-500  text-yellow-900 hover:text-zinc-900 duration-200">
                Enroll Now
              </button>
              <button onClick={() => navigate('/register')} className="text-sky-500 absolute hover:mt-[-2px] hover:ml-[2px] w-40 h-12  border-2 p-2 top-0 text-lg px-6 border-sky-500  duration-200">
                Enroll Now
              </button>
            </div>
          </div>
        </div>
        <div></div>
      </motion.div>
      <motion.div
      className="hidden lg:block md:block sm:block"
        initial={{ opacity: 0, translateX: 200 }}
        whileInView={{
          opacity: 1,
          translateX: 0,
          transition: { duration: 1, type: "spring" },
        }}
      >
        <img
          className="rounded-2xl"
          src="https://images.pexels.com/photos/5474300/pexels-photo-5474300.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt=""
        />
      </motion.div>
    </div>
  );
};

export default HeroSection;
