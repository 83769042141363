import React from 'react'

const GoogleForm = () => {
    window.scroll(0,0)
  return (
    <div className='h-screen bg-zinc-950 overflow-y-scroll p-10' >
        <iframe className='h-full w-full' src="https://docs.google.com/forms/d/e/1FAIpQLSe7ptrfI2ZQ_MWhHAtQh3tx2EZAZnpM2KhMDPoo67WVUY-aXw/viewform?embedded=true" width="640" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default GoogleForm