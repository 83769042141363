import React from "react";
import { motion } from "framer-motion";
const FeaturesSection = () => {
  return (
    <div className="py-8">
      <motion.div initial={{opacity:0}} whileInView={{opacity:1 , translateY:0 ,transition:{duration:1,delay:0.5,type:"spring"}}} className="text-5xl text-center  mb-6 font-sans font-bold">Features</motion.div>
      <div className="lg:grid  mx-auto p-6 gap-4 lg:grid-cols-3">
      
        <motion.div initial={{opacity:0 , translateY:100}} whileInView={{opacity:1 , translateY:0 ,transition:{duration:1,delay:0.5,type:"spring"}}} className="p-[2px] mt-2 lg:mt-0 bg-gradient-to-r from-zinc-500 via-zinc-700 to-zinc-500 rounded-xl">
          <div className=" rounded-xl bg-zinc-950 text-zinc-200 p-4 h-60 flex flex-col  items-center justify-evenly">
            <h1 className="text-3xl font-bold">Learn Coding from Scratch</h1>
            <p>
              Join our coding workshop and learn C, JavaScript, React, MERN
              Stack, Blockchain, Python, and DevOps. No prior experience
              required.
            </p>
          </div>
        </motion.div>
      
      
        <motion.div initial={{opacity:0 , translateY:100}} whileInView={{opacity:1 , translateY:0 ,transition:{duration:1,delay:0.8,type:"spring"}}} className="p-[2px] mt-2 lg:mt-0 bg-gradient-to-r from-zinc-500 via-zinc-700 to-zinc-500 text-zinc-200 rounded-xl">
        <div className=" rounded-xl bg-zinc-950  p-4 h-60 flex flex-col  items-center justify-evenly">
              <h1  className="text-3xl font-bold">Master In-Demand Technologies</h1>
              <p>
                Our comprehensive program covers the latest programming
                languages and technologies, including C, JavaScript, React, MERN
                Stack, Blockchain, Python, and DevOps.
              </p>
          </div>
        </motion.div>
      
      
        <motion.div initial={{opacity:0 , translateY:100}} whileInView={{opacity:1 , translateY:0 ,transition:{duration:1,delay:1,type:"spring"}}} className="p-[2px] mt-2 lg:mt-0 bg-gradient-to-r from-zinc-500 via-zinc-700 to-zinc-500 text-zinc-200 rounded-xl">
          <div className=" rounded-xl bg-zinc-950  p-4 h-60 flex flex-col  items-center justify-evenly">
              <h1  className="text-3xl font-bold">Build Real-World Projects</h1>
              <p>
                Apply your skills to develop practical projects and gain
                hands-on experience in coding, problem-solving, and software
                development.
              </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default FeaturesSection;
