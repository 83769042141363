import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const BenifitSection = () => {
    const navigate = useNavigate()
  return (
    <div className="lg:my-6">
      <div className="lg:grid  mx-auto lg:p-6 p-3 lg:grid-cols-2">
        <motion.div
          initial={{ opacity: 0, translateX: -200 }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            transition: { delay: 0.6, duration: 2, type: "spring" },
          }}
          className="text-zinc-300  flex items-center justify-start font-bold"
        >
          <div className="lg:w-4/5   lg:text-5xl text-2xl  text-left ">
            <div>
              <h1 className="mb-8 ">Unlock Your Potential with Coding Skills</h1>
              <p className="text-sm font-normal mt-2">
                Learning coding skills opens up a world of opportunities, both
                professionally and personally. With coding knowledge, you can
                build websites, develop mobile apps, create software, and even
                explore emerging technologies like blockchain and AI.
              </p>
            </div>
            <div>
                <div className="grid my-6 grid-cols-2 gap-2">
                    <div>
                        <h1 className="text-xl">Career</h1>
                        <p className="text-sm font-normal mt-1">Gain in-demand skills for lucrative careers in technology and software development.</p>
                    </div>
                    <div>
                        <h1 className="text-xl">Personal</h1>
                        <p className="text-sm font-normal mt-1">Enhance problem-solving abilities, boost creativity, and foster a growth mindset.</p>
                    </div>
                </div>
                <div className="mt-2">
            <div className=" w-fit h-14 flex items-center relative">
              <button className=" absolute w-40 border-2 p-2 top-0  h-12 text-lg px-6 border-blue-400  text-blue-900 hover:text-zinc-900 duration-200">
                Enroll Now
              </button>
              <button onClick={() => navigate('/register')}  className="text-stone-100 hover:text-stone-950 hover:bg-white absolute hover:mt-[-4px] hover:ml-[4px] w-40 h-12  border-2 p-2 top-0 text-lg px-6 border-stone-100  duration-200">
                Enroll Now
              </button>
            </div>
          </div>
            </div>
          </div>
        </motion.div>
        <motion.div
        className="mt-2"
          initial={{ opacity: 0, translateX: 200 }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            transition: { delay: 0.6, duration: 2, type: "spring" },
          }}
        >
          <img
            className="rounded-2xl"
            src="https://images.pexels.com/photos/11035471/pexels-photo-11035471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
        </motion.div>
        <div></div>
      </div>
    </div>
  );
};

export default BenifitSection;
