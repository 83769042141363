import React from 'react'
import HeroSection from '../Components/Home/HeroSection'
import DetailSection from '../Components/Home/DetailSection'
import FeaturesSection from '../Components/Home/FeaturesSection'
import BenifitSection from '../Components/Home/BenifitSection'

const Home = () => {
  return (
    <div className='text-white lg:w-3/4  mx-auto pb-12'>
        <HeroSection/>
        <DetailSection/>
        <FeaturesSection/>
        <BenifitSection/>
    </div>
  )
}

export default Home