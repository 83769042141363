import React from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate()
  return (
    <div className='position-fixed w-full text-zinc-300 justify-between flex items-center py-2 px-4 h-12'>
        <div onClick={() => navigate('/')} className={"cursor-pointer"}>
            <img src="./logo.jpg" width={100} alt="" />
        </div>
        <div>
            <button onClick={() => navigate('/register')} >Sign up</button>
        </div>
    </div>
  )
}

export default Navbar